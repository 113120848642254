import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  user: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : {},
  auth_projects: [],
  examples: [
    {
      title: "Classic Gable",
      img: require('../../assets/example/classic gable.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/GableDormerExample`,
      tabIndex: 0,
      index: "ex-0"
    },
    {
      title: "Gambrel",
      img: require('../../assets/example/update gambrel.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Main%20Demo%20-%20Gambrel`,
      tabIndex: 0,
      index: "ex-1"
    },
    {
      tabIndex: 0,
      index: "ex-2",
      title: "Lean-To",
      img: require('../../assets/example/learn-to.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Main%20Demo%20Lean%20To`
    },
    {
      tabIndex: 0,
      index: "ex-3",
      title: "Modern Cottage",
      img: require('../../assets/example/modern cottage.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Modern%20Lean-To`
    },
    {
      tabIndex: 0,
      index: "ex-4",
      title: "Gable with Porch",
      img: require('../../assets/example/Gable with Porch.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Main%20Demo%20Gable%20Side%20Porch`
    },

    {
      tabIndex: 0,
      index: "ex-5",
      title: "Gable with Porch",
      img: require('../../assets/example/Gambrel with Porch.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Main%20Demo%20Gambrel%20w%20Porch`
    },

    {
      tabIndex: 0,
      index: "ex-6",
      title: "Deluxe Cabin",
      img: require('../../assets/example/deluxe cabin.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Hunter/Main%20Demo%20Deluxe%20Cabin`
    },

    {
      tabIndex: 0,
      index: "ex-7",
      title: "Custom Barn",
      img: require('../../assets/example/custom barn.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/Jon/The%20Rancher`
    },

    {
      tabIndex: 1,
      index: "ex-8",
      title: "Carports",
      img: require('../../assets/example/Carports.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Shed%20Ranch%20Inc%20Demo%20-%20Carport`
    },
    {
      tabIndex: 2,
      index: "ex-9",
      title: "Chicken Coop",
      img: require('../../assets/example/chicken-coop.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Chicken%20Coop%20Demo`
    },
    {
      tabIndex: 3,
      index: "ex-10",
      title: "Play Set",
      img: require('../../assets/example/playset-demo.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Playset%20Demo`
    },
    {
      tabIndex: 2,
      index: "ex-11",
      title: "Walk-In Coop",
      img: require('../../assets/example/walk-in-coop.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Mellot_Coop_5x8`
    },
    {
      tabIndex: 2,
      index: "ex-12",
      title: "Deluxe Coop",
      img: require('../../assets/example/Deluxe Coop.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/KTBarns_WalkInCoop`
    }
  ]
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user: {}
  });
};

const getAuth = (state, action) => {
  return updateObject(state, {
    user: action.user
  });
};


const getAuthProjects = (state, action) => {
  return updateObject(state, {
    auth_projects: action.arr
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.GET_AUTH:
      return getAuth(state, action);
    case actionTypes.GET_AUTH_PROJECTS:
      return getAuthProjects(state, action)
    default:
      return state;
  }
};

export default reducer;
