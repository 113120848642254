import React from 'react'
import { connect } from "react-redux";
import { ZSTDDecoder  } from 'zstddec';
import { getAuthProjects } from '../../store/actions/auth';
import { Modal, Button } from 'react-bootstrap'
import { FaTimes, FaChevronDown } from 'react-icons/fa';
import Collapse from '@mui/material/Collapse';
// import Chart from 'chart.js/auto';
// import AnalyticsComp from "../comps/analytics"
import AnalyticsComp from "../comp/customer-anayltics"
import ReferalsComp from "../comp/referals-piechart"

import "../css/project-comp.scss";
import axios from 'axios';

import Pricing from '../pricing/Pricing'

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#ff9f69',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'gray',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: 'gray',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const timePeriods = [
    "Day", "Month", "Year"
]
const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]
class Comp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedProject: {},
            quotesShow: false,
            modalShow: false,
            selectedQuote: {},
            collapseOpen: true,
            pViewCollapseOpen: true,
            pricingViewCollapseOpen: true,
            timePeriod: "Year",
            years: [],
            selectedYear: -1,
            selectedMonth: -1,
            selectedDay: -1,

            CustomerViewData: {
                labels: months,
                datasets: [
                    {
                        label: 'Views',
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: '#26455d',
                    }
                ]
            },

            CustomerViewOption: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                        text: 'Customer Views',
                    },
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 10,
                    },
                }
            },
            CustomerViewRedraw: false,
            RefererViewData: {
                labels: ["Other"],
                views: [0],
                datasets: [
                    {
                        label: '%',
                        data: [100],
                        backgroundColor: [
                            "#6e7c7c"
                        ],
                        borderWidth: 1,
                    },
                ],
            },

            savePricingModalShow: false,

            showAnalyticsSection: true,

            showPricing: true,
            loadCount: 0
        }
    }

    componentDidMount = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const customerId = urlParams.get('customer');
        const admin_password = urlParams.get('password');
        if (!customerId) {
            window.location.href = '/';
            return;
        }
        if (!admin_password) {
            window.location.href = '/';
            return;
        }

        const { getAuthProjectArr } = this.props
        let that = this

        fetch(`${process.env.REACT_APP_SERVER_URL}/get_customer_by_id/${customerId}`)
            .then(response => response.json())
            .then(data => {
                const res = data.data.result;
                console.log("customer : ", res);
                that.setState({
                    authUser: res
                });
            });
        getAuthProjectArr(customerId, (flag) => {
            const { authProjects } = this.props
            const showAnalyticsSectionValues = JSON.parse(localStorage.getItem('showAnalyticsSection'));
            let showAnalyticsSection = true;
            if (showAnalyticsSectionValues) {
                showAnalyticsSection = showAnalyticsSectionValues?.find(value => value.project_id === authProjects[0].id) ? showAnalyticsSectionValues?.find(value => value.project_id === authProjects[0].id)?.value : true
            }
            if (flag && authProjects.length > 0) that.setState({ selectedProject: authProjects[0], showAnalyticsSection })
        });

        const today = new Date();
        const selectedMonth = today.getMonth(); //months from 1-12
        const selectedDay = today.getDate();
        const selectedYear = today.getFullYear();
        const years = []

        for (let i = 0; i < 12; i++) {
            years.push(selectedYear - i)
        }

        this.setState({ years, selectedYear, selectedDay, selectedMonth })
        this.setState(prev => ({
            loadCount: prev.loadCount + 1
        }), () => {
            if (this.state.loadCount === 1) {
                console.log('HERE', customerId)
                this.updateCustomerAdminPass(customerId, admin_password)
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { timePeriod, selectedDay, selectedMonth, selectedYear, selectedProject
            , CustomerViewData, CustomerViewRedraw, RefererViewData, CustomerViewOption, loadCount } = this.state

        if ((timePeriod == prevState.timePeriod)
            && (selectedDay == prevState.selectedDay)
            && (selectedMonth == prevState.selectedMonth)
            && (selectedYear == prevState.selectedYear)
            && (selectedProject.id == prevState.selectedProject?.id))
            return

        if (!selectedProject.id || CustomerViewRedraw) return

        let startStamp, endStamp

        if (timePeriod == "Year") {
            startStamp = new Date(selectedYear, 0, 1)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            endStamp = new Date(selectedYear + 1, 0, 1, 0, 0, 0, 0)
            endStamp = Math.floor(endStamp.getTime() / 1000)
        } else if (timePeriod == 'Month') {
            startStamp = new Date(selectedYear, selectedMonth)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            const dayNum = new Date(selectedYear, selectedMonth, 0).getDate();
            endStamp = startStamp + dayNum * 24 * 3600
        } else if (timePeriod == 'Day') {
            startStamp = new Date(selectedYear, selectedMonth, selectedDay)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            endStamp = startStamp + 24 * 3600
        }

        console.log("time period changed: ", timePeriod, startStamp, endStamp)

        axios
            .post(process.env.REACT_APP_SERVER_URL + "/get_project_traffic/", {
                proj_id: selectedProject.id,
                startStamp,
                endStamp
            })
            .then(res => {
                const data = res.data;
                let labels = []
                let viewData = []
                console.log("data: ", data);
                if (timePeriod == "Year") {

                    months.map((month, i) => {
                        const startT = new Date(selectedYear, i, 1).getTime() / 1000
                        const endT = (i < 11 ? new Date(selectedYear, i + 1).getTime() : new Date(selectedYear + 1, 1).getTime()) / 1000
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(month)
                        return month
                    })

                } else if (timePeriod == 'Month') {

                    const dayNum = new Date(selectedYear, selectedMonth * 1 + 1, 0).getDate();

                    for (let i = 0; i < dayNum; i++) {
                        const startT = new Date(selectedYear, selectedMonth, i + 1).getTime() / 1000
                        const endT = startT + 24 * 3600
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(i + 1)
                    }
                    endStamp = startStamp + dayNum * 24 * 3600

                } else if (timePeriod == 'Day') {

                    for (let i = 0; i < 12; i++) {
                        const startT = new Date(selectedYear, selectedMonth, selectedDay).getTime() / 1000 + 2 * 3600 * i
                        const endT = startT + 2 * 3600
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(`${i * 2}: 00 - ${(i + 1) * 2}:00`)
                    }

                }

                CustomerViewData.labels = labels
                CustomerViewData.datasets[0].data = viewData
                let maxCount = Math.max(...viewData)

                if (maxCount > 10)
                    maxCount = Math.round(maxCount * 1.2)
                else
                    maxCount = 10

                CustomerViewOption.scales.y.suggestedMax = maxCount
                const totalViews = data.data.length
                if (totalViews > 0) {

                    let leftViews = totalViews
                    const refererLabels = []
                    const refererColors = []
                    const refererData = []
                    const refererViews = []

                    data.refers.map(referer => {
                        refererLabels.push(referer.name)
                        refererColors.push(referer.label_color)
                        const filteredData = data.data.filter(item => item.referer.includes(referer.match_url))
                        refererData.push(Math.floor(filteredData.length * 100 / totalViews))
                        leftViews -= filteredData.length
                        refererViews.push(filteredData.length)
                        return referer
                    })

                    refererLabels.push("Other")
                    refererColors.push("#6e7c7c")
                    refererData.push(Math.floor(leftViews * 100 / totalViews))
                    refererViews.push(leftViews)

                    RefererViewData.labels = refererLabels
                    RefererViewData.views = refererViews
                    RefererViewData.datasets[0].data = refererData
                    RefererViewData.datasets[0].backgroundColor = refererColors


                }


                this.setState({ CustomerViewData, RefererViewData, CustomerViewRedraw: true }, () => {
                    this.setState({ CustomerViewRedraw: false })
                })

            })
            .catch(err => {
                console.log("auth failed : ", err.response, err.message)
            });

        this.getData()
    }

    updateCustomerAdminPass = (customerId, admin_password) => {
        const data = {
            customerId,
            admin_password
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }

        fetch(`${process.env.REACT_APP_SERVER_URL}/update_customer_by_id`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {

                } else {
                    if (data.message === 'expired') {
                        window.location.href = '/';
                    }

                }
                console.log('data: ', data);
            })
    }

    getData = () => {
        const { selectedProject } = this.state
        const projUrl = selectedProject.id
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`
        axios.get(reqUrl).then(response => {
            if (response.status === 200) {
                // let project_data = response.data.data.result
                const base64CompressedData = response.data.compressed_data;

                    // Decode Base64 to Uint8Array
                const binaryString = atob(base64CompressedData);
                const compressedData = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    compressedData[i] = binaryString.charCodeAt(i);
                }

                // Initialize ZSTDDecoder and set up the decompression
                const zstd = new ZSTDDecoder();
                zstd.init().then(() =>{ // Initialize the decoder (it fetches the WebAssembly module)

                    // Decompress the data
                    const decompressedData = zstd.decode(compressedData);

                    // Convert the decompressed data to a string and then parse it as JSON
                    const jsonString = new TextDecoder().decode(decompressedData);
                    const project_data = JSON.parse(jsonString);
                    console.log("project_data", project_data);
                    project_data.materials = []
                    // let json_data = quote_id ? project.quote_json : project.json_data
                    let json_data = project_data.json_data
                    console.log('project_data: ', project_data);
                    console.log('json_data: ', json_data);
                    const showPricing = project_data?.showPricing === undefined ? true : project_data?.showPricing;
                    this.setState({
                        project_data,
                        showPricing,
                        json_data,
                    })
                })
            }
        })
    }

    selectProject = (proj) => {
        const showAnalyticsSectionValues = JSON.parse(localStorage.getItem('showAnalyticsSection'));
        let showAnalyticsSection = true;
        if (showAnalyticsSectionValues) {
            showAnalyticsSection = showAnalyticsSectionValues?.find(value => value.project_id === proj.id) ? showAnalyticsSectionValues?.find(value => value.project_id === proj.id)?.value : true;
        }

        this.setState({
            selectedProject: proj,
            showAnalyticsSection
        });
    }

    select_year = (e) => {
        this.setState({ selectedYear: e.target.value })
    }

    select_month = (e) => {
        this.setState({ selectedMonth: e.target.value })
    }


    select_day = (e) => {
        this.setState({ selectedDay: e.target.value })
    }

    toggleQuotesShow = () => {
        let { quotesShow } = this.state
        quotesShow = !quotesShow
        this.setState({ quotesShow })
    }

    getDateTime = (str) => {
        const date = new Date(str.substring(0, 10))
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }

    getPhoneNumber = (str) => {
        return `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(6, 10)}`
    }

    setModalShow = (flag) => {
        this.setState({ modalShow: flag })
    }

    setSavePricingModalShow = (flag) => {
        this.setState({ savePricingModalShow: flag })
    }

    selectQuote = (selectedQuote) => {
        console.log('selectedQuote: ', selectedQuote);
        this.setState({ selectedQuote, modalShow: true })
    }

    toggle_collapse = (collapseOpen) => {
        this.setState({ collapseOpen })
    }

    toggle_pview_collapse = (pViewCollapseOpen) => {
        this.setState({ pViewCollapseOpen })
    }

    toggle_pricingview_collapse = (pricingViewCollapseOpen) => {
        this.setState({ pricingViewCollapseOpen })
    }

    select_time_period = (timePeriod) => {
        this.setState({ timePeriod })
    }

    get_days_map = (num) => {
        const days = []
        for (let i = 0; i < num; i++)days.push(i + 1)
        return days
    }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleSavePricing = (json_data) => {
        const { selectedProject } = this.state;
        const projUrl = selectedProject.id;
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`;
        const csrftoken = this.getCookie('csrftoken');
        const headers = { 'X-CSRFToken': csrftoken };
        let formData = new FormData();
        formData.append('json_data', JSON.stringify(json_data))

        axios.post(reqUrl, formData, { headers }).then(response => {
            if (response.status === 200) {
                // alert("Save Project Success");
                this.setSavePricingModalShow(true);
            } else {
            }
        })
    }

    orderFunc = (a, b) => {
        const Adate = new Date(a.date_time?.substring(0, 10))
        const Bdate = new Date(b.date_time?.substring(0, 10))
        if (Adate < Bdate)
            return 1
        else if (Adate > Bdate)
            return -1
        return 0
    }

    handleShowSection = (e, type) => {
        const { selectedProject } = this.state;
        if (type === 'Analytics') {
            let values = JSON.parse(localStorage.getItem('showAnalyticsSection'));
            const value = {
                project_id: selectedProject.id,
                value: e.target.checked
            }
            if (values) {
                values.push(value);
            } else {
                values = [value];
            }

            localStorage.setItem('showAnalyticsSection', JSON.stringify(values));
            this.setState({
                showAnalyticsSection: e.target.checked
            });
        } else if (type === 'Pricing') {
            this.setState({
                showPricing: e.target.checked
            });
            const projUrl = selectedProject.id;
            const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`;
            const csrftoken = this.getCookie('csrftoken');
            const headers = { 'X-CSRFToken': csrftoken };
            let formData = new FormData();
            formData.append('showPricing', JSON.stringify(e.target.checked))

            axios.post(reqUrl, formData, { headers }).then(response => {
                if (response.status === 200) {
                    // alert("Save Project Success");
                    console.log('resposne: ', response);
                    let project_data = response.data.data.result
                    let json_data = project_data.json_data
                    console.log('project_data: ', project_data);
                    console.log('json_data: ', json_data);
                    this.setState({
                        project_data,
                        json_data,
                    })
                } else {
                }
            })
        }
    }

    render() {
        const { authProjects } = this.props
        const { authUser } = this.state;
        const { selectedProject, quotesShow, modalShow, selectedQuote,
            collapseOpen, pViewCollapseOpen, pricingViewCollapseOpen, timePeriod, years, selectedYear, selectedMonth
            , selectedDay, CustomerViewData, CustomerViewRedraw, RefererViewData, CustomerViewOption, savePricingModalShow } = this.state

        const dayNum = new Date(selectedYear, selectedMonth * 1 + 1, 0).getDate();

        const days = dayNum > 0 ? this.get_days_map(dayNum) : []

        console.log('authProjects: ', authProjects);

        const { project_data, json_data, showPricing, sidingItems, roofingItems, styleItems } = this.state;
        // const showPricing = project_data?.showPricing === undefined ? true : project_data?.showPricing;

        const { showAnalyticsSection } = this.state;

        return <div className="project-comp">
            <div className="header-part header-example">
                <div className="header-title">
                    <h2>Project Dashboard</h2>
                </div>
            </div>
            <div className='price-blocks'>
                <div className='price-content'>
                    <div className='project-list'>
                        <h4>My {quotesShow ? "Quotes" : "Projects"}</h4>
                        <ul>
                            {
                                authProjects.map(proj => <li key={proj.id}
                                    onClick={this.selectProject.bind(this, proj)}
                                    className={selectedProject.id === proj.id ? 'selected' : ''}>{proj.slug ? proj.slug : proj.project_name}</li>)
                            }
                        </ul>
                        <button className='view-quotes' onClick={this.toggleQuotesShow.bind(this)}>
                            {quotesShow && <img src={require("../../assets/member-page/white-arrow.png")} alt='return main' />}
                            {quotesShow ? "MAIN DASHBOARD" : "VIEW QUOTE REQUESTS"}
                        </button>

                        <div className='switch-box' style={{ marginTop: '40px' }}>
                            <h4>Analytics</h4>
                            <IOSSwitch sx={{ m: 1 }} checked={showAnalyticsSection}
                                onChange={e => this.handleShowSection(e, 'Analytics')} />
                        </div>
                        <div className='switch-box'>
                            <h4>Pricing</h4>
                            <IOSSwitch sx={{ m: 1 }} checked={showPricing}
                                onChange={e => this.handleShowSection(e, 'Pricing')} />
                        </div>
                    </div>

                    {selectedProject.id && <div className='project-show'>
                        <h4>
                            {quotesShow ? "Customer Quote Requests" : selectedProject.slug ? selectedProject.slug : selectedProject.project_name}
                            <button className={'btn-collapse' + (collapseOpen ? " opened" : "")} onClick={() => this.toggle_collapse(!collapseOpen)}>
                                <FaChevronDown />
                            </button>
                        </h4>

                        <Collapse in={collapseOpen}>
                            <div className={`content ${quotesShow ? "quote-show" : ""}`}>

                                <div className='picture-part'>
                                    <p style={{ marginTop: 20 }}>
                                        <a href={`${process.env.REACT_APP_SERVER_URL}/client/?project=${selectedProject.id}`} target="blank" className="bg-gray link-block link-project-detail bordered">
                                            <img src={process.env.REACT_APP_SERVER_URL + selectedProject.proj_img} className="img-responsive center-block project-item-img" alt="default" />
                                        </a>
                                    </p>
                                </div>

                                <div className='description-part'>
                                    <p>
                                        <b className="embeded_title">How to use your 3D Configurator.</b>
                                    </p>
                                    <p>
                                        <b style={{ fontWeight: 900, fontSize: 15 }}>To embed the 3D Experience directly into your website follow these instructions:</b>
                                    </p>
                                    <p>
                                        1) Copy from one of the code options below. <br />
                                        2) Navigate to your site and edit the HTML code. <br />
                                        3) Paste the code where you want the 3D View to appear on your site.<br />
                                    </p>
                                    <br />
                                    <p>
                                        <b style={{ fontWeight: 900, fontSize: 15 }}>For specific instructions on how to embed the 3D Configurator on various platforms, please be sure to reference your platforms documentation on how to embed content. </b>
                                    </p>
                                    <br />
                                    <p>
                                        <b>iFrame Code</b> <br />
                                        <code>
                                            &lt;iframe
                                            src={`"${process.env.REACT_APP_SERVER_URL}/client/?project=${selectedProject.id}"`}
                                            &nbsp; frameborder="0" width="100%" height="400px"&gt;&lt;/iframe&gt;
                                        </code>
                                    </p>
                                    <p>
                                        <b>Embed Widget</b> <br />
                                        {
                                            authUser && authUser.id &&
                                            <code>
                                                &lt;iframe
                                                src={`"${process.env.REACT_APP_SERVER_URL}/project-list/${authUser.id}"`}
                                                &nbsp; frameborder="0" width="100%" height="400px"&gt;&lt;/iframe&gt;
                                            </code>
                                        }
                                    </p>
                                </div>

                                <div className='quote-list'>
                                    <table>
                                        <tbody>
                                            {
                                                authUser && authUser.customer_quotes.sort(this.orderFunc)?.map(quote =>
                                                    <tr key={quote.id}>
                                                        <td><button onClick={this.selectQuote.bind(this, quote)}>View Details</button></td>
                                                        <td>{this.getDateTime(quote.date_time)}</td>
                                                        <td>{`${quote.first_name} ${quote.last_name}`}</td>
                                                        <td>{quote.user_email}</td>
                                                        <td>{this.getPhoneNumber(quote.user_phone)}</td>
                                                        <td>{quote.user_zipcode}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Collapse>

                        {
                            !quotesShow &&
                            <>
                                {
                                    showAnalyticsSection &&
                                    <>
                                        <h4>
                                            Project Analytics
                                            <button className={'btn-collapse' + (pViewCollapseOpen ? " opened" : "")} onClick={() => this.toggle_pview_collapse(!pViewCollapseOpen)}>
                                                <FaChevronDown />
                                            </button>
                                        </h4>

                                        <Collapse in={pViewCollapseOpen}>
                                            <div className='select-period'>
                                                <div className='date-area'>

                                                    {timePeriod == 'Day' && <select onChange={this.select_day.bind(this)} value={selectedDay}>
                                                        {
                                                            days.map((day) => <option key={day} value={day}>{day}</option>)
                                                        }
                                                    </select>}

                                                    {['Month', 'Day'].includes(timePeriod) && <select onChange={this.select_month.bind(this)} value={selectedMonth}>
                                                        {
                                                            months.map((month, i) => <option key={month} value={i}>{month}</option>)
                                                        }
                                                    </select>}

                                                    <select onChange={this.select_year.bind(this)} value={selectedYear}>
                                                        {
                                                            years.map(year => <option key={year} value={year}>{year}</option>)
                                                        }
                                                    </select>

                                                </div>

                                                <div className='button-area'>
                                                    {
                                                        timePeriods.map(item => <button className={timePeriod == item ? "selected" : ""} key={item} onClick={this.select_time_period.bind(this, item)}>{item}</button>)
                                                    }
                                                </div>

                                            </div>


                                            <div className='content customer-views'>
                                                <AnalyticsComp data={CustomerViewData} option={CustomerViewOption} redraw={CustomerViewRedraw} />
                                            </div>

                                            <div className='content pie-chart'>
                                                <ReferalsComp data={RefererViewData} redraw={CustomerViewRedraw} />
                                            </div>

                                        </Collapse>
                                    </>
                                }


                                {
                                    showPricing &&
                                    <>
                                        <h4>
                                            Pricing
                                            <button className={'btn-collapse' + (pricingViewCollapseOpen ? " opened" : "")} onClick={() => this.toggle_pricingview_collapse(!pricingViewCollapseOpen)}>
                                                <FaChevronDown />
                                            </button>
                                        </h4>

                                        <Collapse in={pricingViewCollapseOpen}>
                                            <Pricing
                                                json_data={json_data}
                                                project_data={project_data}
                                                handleSavePricing={this.handleSavePricing}
                                            />
                                        </Collapse>
                                    </>
                                }
                            </>
                        }
                    </div>}
                </div>
            </div>

            <Modal
                show={modalShow}
                onHide={() => this.setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-quote-details"
            >
                <Modal.Header>

                    <button className='btn-exit' onClick={() => this.setModalShow(false)}>
                        <FaTimes />
                    </button>

                </Modal.Header>

                <Modal.Body>
                    <div className="email-success">
                        <img src='logo192.png' alt='Logo' />
                        <h3>
                            Quote Details
                        </h3>
                        <div className='content-block'>
                            <h5>Lead Details:</h5>
                            <p>Name: {`${selectedQuote.first_name} ${selectedQuote.last_name}`}</p>
                            <p>Email: {selectedQuote.user_email}</p>
                            <p>Phone: {this.getPhoneNumber(selectedQuote.user_phone ? selectedQuote.user_phone : "")}</p>
                            <p>ZIP Code: {selectedQuote.user_zipcode}</p>
                            <p>Date Of Referral: {selectedQuote.date_time}</p>
                            <p>Referral Origin: <br />
                                {
                                    authUser && authUser.first_name && <a href={`${process.env.REACT_APP_SERVER_URL}/project/${authUser.firstname}/${project_data?.project_name}?q=${selectedQuote.id}`} target="_blank">{`${process.env.REACT_APP_SERVER_URL}/project/${authUser.firstname}/${project_data?.project_name}?q=${selectedQuote.id}`}</a>
                                }

                            </p>
                        </div>

                        <div className='content-block'>
                            <h5>Product Details:</h5>
                            <p>Shed Name: {selectedQuote.shed_name}</p>
                            <p>Shed Size: {selectedQuote.size_text}</p>
                            <p>Size Price: {formatter.format(selectedQuote.size_cost)}</p>

                            <p>Siding Type: {selectedQuote.siding_name}</p>
                            <p>Siding Price: {formatter.format(selectedQuote.siding_cost)}</p>

                            <p>Roofing Type: {selectedQuote.roofing_name}</p>
                            <p>Roofing Price: {formatter.format(selectedQuote.roofing_cost)}</p>

                            <p>Siding Color: {selectedQuote.siding_color}</p>
                            <p>Siding Color Price: $0.00</p>

                            <p>Trim Color: {selectedQuote.trim_color}</p>
                            <p>Trim Color Price: $0.00</p>

                            <p>Roof Color: {selectedQuote.roof_color}</p>
                            <p>Roof Color Price: $0.00</p>
                        </div>

                        <div className='content-block'>
                            <h5>Accessories:</h5>
                            <p>Front: {selectedQuote.accessories_front}</p>
                            <p>Front Price: {formatter.format(selectedQuote.asset_front_cost)}</p>

                            <p>Back: {selectedQuote.accessories_back}</p>
                            <p>Back Price: {formatter.format(selectedQuote.asset_back_cost)}</p>

                            <p>Left: {selectedQuote.accessories_left}</p>
                            <p>Left Price: {formatter.format(selectedQuote.asset_left_cost)}</p>

                            <p>Right: {selectedQuote.accessories_right}</p>
                            <p>Right Price: {formatter.format(selectedQuote.asset_right_cost)}</p>
                        </div>

                        <div className='content-block'>
                            <h5>Total Quote Price: {formatter.format(selectedQuote.size_cost + selectedQuote.siding_cost + selectedQuote.roofing_cost + selectedQuote.asset_front_cost + selectedQuote.asset_back_cost + selectedQuote.asset_left_cost + selectedQuote.asset_right_cost)}</h5>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer onClick={() => this.setModalShow(false)}>
                    <Button >Ok</Button>
                </Modal.Footer>

            </Modal>

            <Modal
                show={savePricingModalShow}
                onHide={() => this.setSavePricingModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-save-pricing"
            >
                <Modal.Body>
                    <div className="price-success">
                        <h3>
                            Your pricing Information has been saved successfully
                        </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer onClick={() => this.setSavePricingModalShow(false)}>
                    <Button >Ok</Button>
                </Modal.Footer>

            </Modal>

        </div>

    }
}

const mapStateToProps = state => {
    return {
        // authUser: state.auth.user,
        authProjects: state.auth.auth_projects
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAuthProjectArr: (user_id, callback) => dispatch(getAuthProjects(user_id, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);

